.info-label {
}

.info-value-sec {
  margin: 12 0 18 0;
}

.info-label-value {
  color: black;
  font-size: 17px !important;
}
.info-label-value_mobile {
  color: black;
  font-size: 12px !important;
}
.info-locations-tab {
  overflow-y: auto;
  max-height: 60vh;
  padding-right: 20;
}

.info-locations-tab::-webkit-scrollbar {
  /* width: 8px;  */
  background-color: #fffff100 !important;
}

.info-locations-tab::-webkit-scrollbar-track {
  display: none; /* Hide the scrollbar track */
}

.info-locations-tab::-webkit-scrollbar-thumb {
  background-color: white;
  /* border-radius: 8px;  */
}

/* For Firefox */
.info-locations-tab {
  scrollbar-width: thin; /* Width of the scrollbar */
}

.info-locations-tab::-webkit-scrollbar-thumb {
  background-color: #666; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Radius of the scrollbar thumb */
}
.View_Details {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.View_Details_Headings {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.945rem;
  letter-spacing: 0.01071em;
  color: rgba(36, 36, 36, 0.87);
  font-weight: 700;
}
.Chat_Child_View_Shipment {
  /* margin-left: -50px; */
  border: 1px rgb(202, 201, 201) solid;
  overflow-y: scroll;
  /* height: 95vh; */
  width: 50vw;
}
.Chat_Child_View_Shipment.table {
  font-size: 12px;
  margin-top: 10px;
  border-collapse: collapse; /* Add this line to remove cell spacing */
}

.Chat_Child_View_Shipment.td {
  white-space: nowrap;
  border: none; /* Set border to none to remove borders */
  text-align: left;
  padding: 8px;
}
.bol-label {
  font-weight: 700;
  font-size: 17px;
  margin-right: 5px;
  color: black;
}

.bol-value {
  font-size: 15px;
}

.labels-label {
  font-weight: 700;
  font-size: 12px;
  margin-right: 5px;
  color: black;
}

.labels-value {
  font-size: 12px;
}
