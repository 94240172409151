.centered-container {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  min-height: 100vh;
  margin: 10px;
}

.columns-header {
  display: flex;
  justify-content: center;
}

.table-row {
  display: flex;
  justify-content: center;
}
.table-row-loader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 70%;
}
.columns-container {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 1;
  padding: 10px;
}
.Csv-Error {
  color: red;
  margin-top: 20vh;
}
