.side-nav-bar {
  width: 6vw;
  
  padding: 20px 5px 30px 5px;
  /* position: fixed; */
  background-color: #ffffff !important;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.side-nav-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: x-small;
  color: #757575;
  margin: 10px 0px 10px 0px;
  padding: 7px 0px 7px 0px;
  border-radius: 8px;
}

.side-nav-btn:hover {
  color: #757575;
  background-color: #75757520;
}

.side-nav-btn-span {
  margin-top: 6px;
}

.side-nav-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 0px 20px;
  justify-content: space-between;
  align-content: center;
}
.side-nav-btn.highlighted {
  background-color: #3484db; /* Highlight background */
  color: #f0f0f0; /* Highlight text color */
  font-weight: bold; /* Optional for emphasis */
}
