/* Ensure the input container is relatively positioned */
.custome_date_picker .react-datepicker__input-container,
.shipments_date_picker .react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%; /* Make sure input container takes full width */
}

/* Style the input field */
.custome_date_picker .react-datepicker__input-container input,
.shipments_date_picker .react-datepicker__input-container input {
  border-radius: 3px;
  border: 1px solid #ccc; /* Subtle border */
  padding: 5px;
  padding-right: 35px; /* Added space for the icon */
  width: 100%; /* Ensure input width fills container */
  font-size: 13px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Position calendar icon inside the input */
.custome_date_picker .react-datepicker__input-container .calendar-icon,
.shipments_date_picker .react-datepicker__input-container .calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position of icon to the right */
  transform: translateY(-50%); /* Vertically center the icon */
  pointer-events: none; /* Ensure the icon doesn't interfere with input */
  color: #333; /* Dark color for the icon */
  z-index: 1; /* Make sure the icon is on top */
}

/* Style the calendar dropdown buttons */
.custome_date_picker .react-datepicker__navigation,
.shipments_date_picker .react-datepicker__navigation {
  background-color: #1976d2;
  color: white;
}

.custome_date_picker .react-datepicker__triangle,
.shipments_date_picker .react-datepicker__triangle {
  border-bottom-color: #1976d2;
}

/* Responsive adjustments */
@media (min-width: 1200px) {
  .custome_date_picker .react-datepicker__input-container,
  .shipments_date_picker .react-datepicker__input-container {
    width: auto; /* Allow input container to adjust on large screens */
  }
}

/* Ensure the calendar stays responsive */
.custome_date_picker .react-datepicker__triangle,
.shipments_date_picker .react-datepicker__triangle {
  border-bottom-color: #1976d2; /* Triangle color */
}

.custome_date_picker .react-datepicker__input-container input {
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 5px;
  width: 22.1vw;
  font-size: 13px;
}

.shipments_date_picker .react-datepicker__input-container input {
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 5px;
  width: 175px;
  font-size: 13px;
}

/* Default calendar size */
.custome_date_picker .react-datepicker__month,
.shipments_date_picker .react-datepicker__month {
  width: 100%;
}

/* Media Queries for responsiveness */
@media (max-width: 600px) {
  /* On small screens, set the calendar width to 80% */
  .custome_date_picker .react-datepicker,
  .shipments_date_picker .react-datepicker {
    width: 80% !important;
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  /* On medium screens, set the calendar width to 90% */
  .custome_date_picker .react-datepicker,
  .shipments_date_picker .react-datepicker {
    width: 90% !important;
    font-size: 13px;
  }
}

@media (min-width: 1025px) {
  /* On larger screens, set the calendar width to 100% */
  .custome_date_picker .react-datepicker,
  .shipments_date_picker .react-datepicker {
    width: 100% !important;
    font-size: 14px;
  }
}
