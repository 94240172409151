.box_shadow {
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.bg_img {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10 5 0 20;
  margin: 0 0 110 0;
  background-size: cover;
  height: 150;
}
.profile_label {
  margin: 10 0 1 0;
  font-weight: 700;
  font-size: 12;
}
