.Box_Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40vw;
  height: 70vh;
}
.Box_Parent {
  display: flex;
  justify-content: center;
  padding: 20;
  min-height: 90vh;
}
.Card_Parent {
  display: flex;
  align-items: center;
  margin-top: 20;
  width: 35vw;
  /* height: 80px; */
}
.Carrier_Logo {
  width: 70px;
  object-fit: fill;
}
.Card_Parent:hover {
  background-color: rgb(229, 225, 225);
}

.CarrierName_Btn {
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.InnerDialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    /* max-width: 200vw; */
    /* width: 200vw; */
    /* height: 400; */
  }
}
.No_Carriers {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NextBtnDiv2 {
  display: flex;
  justify-content: flex-end;
}
.NextBtnDiv2_mobile {
  display: flex;
  flex-direction: column;
}
