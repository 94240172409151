.scroll {
  /* height: 70vh; */
}
.scroll::-webkit-scrollbar {
  /* display: none; */
}

::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* -webkit-border-radius: 10px; */
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  /* background: rgb(80, 157, 235); */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  /* background: rgb(80, 157, 235); */
}
.MuiTypography-root.MuiTypography-h6 {
  color: #4e79d2;
}
.Hazmat {
  border-style: solid;
  border-width: thin;
  border-color: rgb(194, 196, 197);
  width: 180;
  height: 35;
  border-radius: 3px;
}
.Stackable {
  margin-top: 8;
  border-style: solid;
  border-width: thin;
  border-color: rgb(194, 196, 197);
  width: 280;
  height: 34;
  font-size: 12;
  border-radius: 3px;
}
/* .css-1xhypcz-MuiStack-root > .MuiTextField-root {
  min-width: 50px;
  width: 95px;
} */
.Shipment_Tabs {
  color: #3361ff;
  font-weight: 500;
  font-size: 14px;
}
.Tabs_Badge_Title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
}
.hovered {
  /* background-color: black; */
}

/* CSS */
.table-row-buttons {
  position: absolute;
  display: none;
  margin-top: -36px;
  /* width: 230; */
  background: #eeeeee00;
}
.MuiTabs-scroller {
  overflow-x: auto !important;
  display: flex;
}

.MuiTabs-flexContainer {
  display: inline-flex; /* Ensures that tabs do not stretch */
}

/* Optional: Adjust input styles when in the portal */
.shipments .react-datepicker__input-container input {
  border-radius: 10px;
  border: 1px solid #ccc; /* Add a subtle border to the input */
  padding: 5px;
  width: 220px;
}

/* Optional: Modify the calendar buttons for better UI */
.shipments .react-datepicker__navigation {
  background-color: #1976d2; /* Change the background color of navigation buttons */
  color: white; /* Button text color */
}

.shipments .react-datepicker__triangle {
  border-bottom-color: #1976d2; /* Change the triangle color to match the button */
}

.react-datepicker-popper {
  z-index: 99999 !important;
}

.react-datepicker__input-container input {
  min-width: 210px !important;
  width: fit-content;
  font-size: 15;
}
