.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: none;
}
/* .css-z7uhs0-MuiStepConnector-line {
  margin: 20 25 0 25;
} */
/* .css-uhb5lp {
  min-width: 900;
} */
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #2196f3;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #2196f3;
}
.PickupDialogParent {
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87);
}
.PickupCloseIcon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.56);
}
.PickupDailogContent {
  width: 900px;
  height: 730px;
  overflow-y: scroll;
  border-radius: 4px;
}
.PickupStepperParent {
  height: 62px;
  width: 852px;
  left: 24px;
  /* right: 24px; */
  top: 24px;
  border-radius: 0px;
  margin-top: 20;
}

.css-zpcwqm-MuiStepConnector-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
}
.PicupStepper {
  cursor: pointer;
  background-color: aliceblue;
}
.LocationsClass {
  display: flex;
  /* justify-content: space-evenly; */
}
.ParentFlexDiv {
  /* margin-left: 10vw; */
  display: flex;
  align-items: 'start';
  flex-direction: column;
}
.OriginTitle {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}
.DestinationTitle {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 15;
}

.ServicesTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  /* margin-top: 15; */
  /* margin-right: 350; */
}
.ServicesTitleDelivery {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 15;
  margin-left: 180;
}
.AdditionalFeeTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  margin: 10 0 0 0;
}
.ServicesSwitches {
  margin-top: 15px;
  display: flex;
  width: 852px;
}
.PreviewServicesSwitches {
  /* margin-bo: 0px; */
  display: flex;
  /* width: 852px; */
}
.ServicesSwitcheLabels {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.TopPreviewSwitcheLabels {
  margin-top: 10;
  font-size: 14px;
  font-weight: 500;
}
.PreviewSwitcheLabels {
  margin-top: 10;
  font-size: 14px;
  font-weight: 500;
}
.LocationTextField {
  color: rgba(178, 46, 46, 0.38);

  margin-top: 0;
}
.NextBtnDiv {
  display: flex;
  justify-content: flex-end;
}
.NextBtnRates {
  margin-top: 10vh;
  display: flex;
  justify-content: flex-end;
}
.BtnColor {
  background-color: #2196f3;
  margin: 0 0 0 10;
}
.NextBtnIcon {
  font-size: 20;
  margin-left: 10;
}
.PrevBtnIcon {
  font-size: 20;
  margin-right: 10;
}
.MarginTop {
  margin-top: 25;
}
.Grid_Item {
  background-color: fff;
  padding: 0;
  /* margin: 0 5vw 0 5vw; */
}
.Grid_Item_Get_Quote {
  background-color: #fff !important;
  padding: 20 20 20 20 !important;
  margin: 0 1.2vw 1 1.2vw !important;
}
.RightMarginItems {
  margin-top: 25;
  margin-left: 15;
}
/* .scroll ::-webkit-scrollbar {
  display: none;
} */
.RemarksParent {
}
.MuiTypography-root.MuiTypography-h6 {
  color: #4e79d2;
}
.Hazmat {
  border-style: solid;
  border-width: thin;
  border-color: rgb(194, 196, 197);
  width: 180;
  height: 35;
  border-radius: 3px;
}
.Stackable {
  margin-top: 8;
  border-style: solid;
  border-width: thin;
  border-color: rgb(194, 196, 197);
  width: 280;
  height: 34;
  font-size: 12;
  border-radius: 3px;
}
.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  min-width: 50px;
  width: 95px;
}
.OuterCopyNmbBook {
  display: flex;
  /* background-color: red; */
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: rgb(232, 235, 235);
  outline: solid rgb(247, 252, 252);
  /* margin: 0 140 0 140; */ /* Remove or adjust this line */
  outline-width: 1px;
  border-radius: 3px;
  /* width: 100%; */
}
.CopyNmbBook {
  /* background-color: green;
  display: flex; */
  background-color: rgb(232, 235, 235);
  border: 0px;
  width: auto;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
}
.ParentCopyNmbBook {
  /* background-color: yellow; */
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.InnerDialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 500;
    height: 400;
  }
}
.Other_Carrier_Dialog {
  /* max-width: 200; */
}
.Rates_Tab {
  margin-top: 0;
  margin-left: -10px !important;
  display: flex;
  justify-content: space-around;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: rgba(30, 46, 170, 0.08);
  border-radius: 12px;
  /* max-height: 150; */
}
.carriers_logo {
  width: 26;
  height: 26;
  /* object-fit: cover; */
}
.carriers_logo_Parent {
  background-color: rgb(239, 243, 247);
  border-radius: 50px;
  /* width: 36;
  height: 36; */
  margin: 0 10 0 -15;
}
.others_carrier_logo {
  margin: 5 0 0 5;
  color: #4e79d2;
}

.btn-schedule-pickup {
  position: absolute !important;
  bottom: 10px;
  right: 130px;
}
.custom-timepicker {
  font-size: 20px; /* Adjust the font size as needed */
}
