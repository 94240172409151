.Carrier_Manager_Parent {
  /* border: 1px solid black; */
  margin: 10px;
}
.Chat_Parent {
  border: 0.5px rgb(207, 207, 207) solid;
  height: auto !important;
  /* position: relative; */
}
.Chat_Parent_mobile {
  border: 0.5px rgb(207, 207, 207) solid;
  height: 100%;
}
.Chat_Parent_Success {
  margin-top: 5%;
  border: 0.5px rgb(207, 207, 207) solid;
  height: 70vh !important;
}
.Chat_Parent_Success_mobile {
  margin-top: 5%;
  border: 0.5px rgb(207, 207, 207) solid;
  height: 40vh !important;
}
.Chat_Child {
  border: 1px rgb(236, 230, 230) solid;
  border-bottom: 0.1px rgb(236, 230, 230) dotted;
  overflow-y: scroll;
  /* height: 75% !important; */
  /* width: 32vw; */
}
.User_Input {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 50 !important;
  border: 0.5px rgb(207, 207, 207) solid !important;
  border-bottom: 0 !important;
}
.Rates_Input {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 30;
  border: 0.5px rgb(207, 207, 207) solid;
  /* border-bottom: 0; */
}
.datePicker {
  width: 100%;
}
.Rates_Input_delivery {
  width: 100%;
  bottom: 0;
  /* margin-left: 5; */
  height: 30;
  border: 0.5px rgb(207, 207, 207) solid;
}

.User_Input_Icon {
  position: absolute;
  bottom: 0;
  right: 0%;
  width: 10%;
  height: 100;
  cursor: pointer;
  /* border: 0.5px rgb(207, 207, 207) solid;
  border-bottom: 0; */
}
.User_Input:active {
  border: 0.5px rgb(207, 207, 207) solid;
  border-bottom: 0;
}
.User_Input:hover {
  /* cursor: pointer; */
  border: 1.5px rgb(207, 207, 207) solid;
  /* border-bottom: 0; */
}
.Chat_Carrier_Logo {
  background-color: red;
}
.chat-message {
  display: flex;
  align-items: flex-end;
  margin: 8px;
  max-width: 70%;
  padding: 10px;
  border-radius: 15px;
  position: relative;
  font-size: 16px;
  word-break: break-word;
}

.message-text {
  flex: 1;
}

.my-message {
  background-color: #0078ff;
  color: white;
  align-self: flex-end;
}

.other-message {
  background-color: #f0f0f0;
  color: #333;
}

.arrow-left,
.arrow-right {
  width: 0;
  height: 0;
  position: absolute;
}

.arrow-left {
  left: -15px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid #f0f0f0; /* Match the background color of other messages */
}

.arrow-right {
  right: -15px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #0078ff; /* Match the background color of my messages */
}
.carrier_triangle {
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 27px solid #e0f2f1;
  rotate: 243deg;
  margin: -12px 0px 0px 220px; /* Adjust the size and color of the triangle */
}
.manager_triangle {
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 27px solid #eceff1;
  rotate: 243deg;
  margin: -11px 0px 0px -9px; /* Adjust the size and color of the triangle */
}
.agency_carrier_triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #e0f2f1;
  rotate: 180deg;
  margin: 48px -298px 2px 0px; /* Adjust the size and color of the triangle */
}
.agency_manager_triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #eceff1;
  rotate: 180deg;
  margin: 10px 0px -63px -11px; /* Adjust the size and color of the triangle */
}
