.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-top: 0px;
}
.custom-table th,
.custom-table td {
  border: 1px solid #000;
  padding: 5px;
  text-align: left;
  min-height: 30px; /* Set a minimum height for the cells */
  height: 30px; /* Set a fixed height for the cells */
  text-align: center;
}
.custom-col-100 {
  width: 80px;
}
.custom-col-500 {
  width: 600px;
}
.page-break {
  page-break-before: always;
  page-break-inside: avoid;
  margin-top: 100px; /* Adjust as needed */
}

.page-break5 {
  page-break-before: always;
  page-break-inside: avoid;
  margin-top: 15px; /* Adjust as needed */
}
