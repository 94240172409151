/* .Card {
  margin: 10 10 40 10;
} */
.CardBody {
  /* margin: 10 10 0 10; */
}
.ParentCol {
  margin-left: -22vw;
}
.Parent {
  font-size: 13;
}
.ShipStatus {
  width: 22vw;
  border-radius: 0;
}
.ShipStatusDate {
  font-size: 11;
  font-weight: 600;
  margin: 10 0 0 7;
}
.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #f5f5f5;
}
.ShipDocumentsTitle {
  display: flex;
  justify-content: center;
}
.ShipHistoryApiErrParent {
  display: flex;
  justify-content: center;
  height: 50vh;
}
.ShipHistoryApiErrInner {
  align-self: center;
  display: inline-flex;
  color: #d32f2f;
}
.ShipHistoryApiErr {
  margin-left: 10;
}
.blue-connector {
  background-color: blue; /* Change the background color to blue */
  height: 2px; /* Adjust the height as needed */
}
.View_Details_Headings {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.945rem;
  letter-spacing: 0.01071em;
  color: rgba(36, 36, 36, 0.87);
  font-weight: 700;
}
