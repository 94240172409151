.kanban-column {
  display: flex;
  flex-direction: column;
  width: 32%;
}

.kanban-header {
  padding: 5px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 3px solid lightgray;
}

.kanban-column-body {
  margin-top: 3px;
  border-radius: 6px;
  text-align: start;
  padding: 5px;

  max-height: 65vh;
  overflow-y: auto;
}

.kanban-column-body::-webkit-scrollbar {
  width: 2px;
  /* height: 0px; */
}

/* Handle */
.kanban-column-body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: gray;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.kanban-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* background-color: #d3d3d345; */
  border: 1px solid lightgray;
  border-left: 2px solid lightgray;
  /* box-shadow: 2px #6699FF; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /* border-radius: 4px; */
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 3px;
  height: 68px;
  position: relative;
  /* background-color: #F5F5F5; */
}
.kanban-card:hover {
  background-color: #fafafa;
}

.kanban-card.active {
  border: 2px solid black !important;
  border-radius: 5px !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.kanban-card.active .kanban-selectbox {
  opacity: 1 !important;
  pointer-events: auto !important;
}

/* .kanban-selectbox {
    position: absolute;
    top: 7px;
    left: -8px;
    opacity: 0;
    pointer-events: none
} */

.kanban-card:hover .kanban-selectbox {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.rate-card-prie {
  font-weight: bolder !important;
  /* color: black; */
  /* font-size: 25px !important; */
}
.rates-data-container_quick_quote {
  width: 100% !important;
  display: flex !important;
  flex-direction: column;
  height: 63vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  align-items: center !important;
  padding: 5px 12px 0px 8px;
}
.rates-data-container_quick_quote_mobile {
  width: 105% !important;
  display: flex !important;
  flex-direction: column;
  height: 70vh !important;
  overflow-y: scroll !important;
  overflow: hidden;
  align-items: center !important;
  padding: 1px 0px 0px 7px;
}
.rates-data-container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column;
  height: 67% !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  align-items: center !important;
  padding: 5px 12px 0px 8px;
}
.rates-data-container_BookShipmet {
  width: 100% !important;
  display: flex !important;
  flex-direction: column;
  height: 60vh !important;
  overflow-y: scroll !important;
  align-items: center !important;
  padding: 5px 12px 0px 8px;
}
.filter-container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  align-items: center;
}

.table-card-logo {
  /* border-right: 1px solid lightgray; */
  overflow-y: hidden;
}

.table-card {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  /* cursor: pointer; */
  border: 1px solid lightgray;
  border-radius: 3px;
  /* margin: 5px 10px 5px 10px; */
  padding: 10px 3px 10px 5px;
  height: 100px;
  /* position: relative; */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 12px;
  /* width: 290px; */
}

.detail-bottom-drawer {
  align-items: center;
  max-width: 99vw;
}

.table-card:hover {
  background-color: #fafafa;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px #b5c9fc;
}
.table-card.active {
  background-color: #edf2ff;
  /* border: 1px solid lightgray !important; */
  /* border-radius: 5px !important; */
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 2px #b5c9fc; */
}

.table-card.active .kanban-selectbox {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.table-card:hover .kanban-selectbox {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.kanban-all {
  background-color: #6699ff;
}

.kanban-all-light {
  background-color: #6699ff50;
}

.kanban-standard {
  background-color: #9933ff;
}

.kanban-standard-light {
  background-color: #9933ff40;
}

.kanban-guaranteed {
  background-color: #00cc33;
}

.kanban-guaranteed-light {
  background-color: #00cc3320;
}

.rates-list-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.list-row {
  display: flex;
  align-items: center;
  margin-bottom: 5;
  width: 100%;
}

.list-row-prices {
  display: flex;
  max-width: 100% !important;
  /* overflow-x: scroll; */
}

.card-prices {
  margin: 5;
  padding: 8;
  width: 195px;
  display: flex;
  justify-content: space-between;
  /* border-radius: 8px; */
  border: 1px solid lightgray;
}
