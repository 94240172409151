.Navbar-TMS-Parent {
  display: flex;
  align-items: center;
  /* width: 70px; */
}
.Navbar-TMSLogo {
  height: 18px;
  width: 18px;
}
.Navbar-TMS {
  padding: 5px 178px 0px 10px;
  color: #4e79d2;
  font-size: 25px;
}
.Navbar-AgenciesIcon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
  color: #6ba3e9;
  /* margin-left: 60px; */
}
.Navbar-ShipmentsIcon {
  height: 24px;
  width: 24px;
  color: #6ba3e9;
  margin-right: 10px;
}
.Navbar-ShipmentsDropDownIcon {
  height: 24px;
  width: 24px;
  color: #6ba3e9;
  margin-left: -21px;
}
.Navbar-Carriers {
  color: #6ba3e9;
  margin-right: 10px;
}
.Navbar-Icon-Name {
  margin-right: 20px;
  color: #4d5969;
  font-size: 12px;
  font-weight: 700;
}
.Navbar-Search {
  width: 350px;
  margin-left: 10px;
  border-radius: 20px;
  height: 35px;
  /* background-color: rgb(238, 243, 249); */
}
.Right-MoonIcon {
  height: 24px;
  width: 24px;
  color: #6ba3e9;
  margin-right: 8px;
  /* margin-left: 10px; */
  cursor: pointer;
}
.Right-Grid-Icon {
  height: auto;
  /* color: #8bb7ed; */
  /* background-color: #8bb7ed; */
  /* margin-right: 15px; */
}
.Grid-Noti-Icon {
  height: auto;
  /* margin-right: 1px; */
}
.Permissions {
  color: rgba(0, 0, 0, 0.87);
  margin-left: 10px;
}
