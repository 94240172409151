.Carrier_div {
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 80vw;
  height: 10vh;
}
.Carrier_div_Negotiate {
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 45vw;
  height: 10vh;
}
.List_Carrier_div {
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 80vw;
}
.List_Carrier_div_Negotiate {
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 45vw;
}
.Rates_Carrier_div {
  margin-bottom: 40;
  outline: 1p1x solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 50vw;
}
.Error_Carrier_div {
  margin-bottom: 40;
  margin-left: 5;
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 80vw;
}
.Error_Carrier_div_LTL {
  margin-top: 10;
  margin-bottom: 20;
  margin-left: 0;
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 66vw;
}
.Error_Carrier_div_Negotiate {
  margin-bottom: 40;
  margin-left: 5;
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  width: 45vw;
}

.Carrier_Name {
  width: auto;
  font-size: 12;
  font-weight: 700;
  position: absolute;
  margin: -80 0 0 20;
  background-color: white;
  white-space: nowrap;
}
.List_Carrier_div:hover .Carrier_Name {
  width: auto;
  font-size: 12;
  font-weight: 700;
  position: absolute;
  margin: -80 0 0 20;
  background-color: rgb(247, 247, 247);
  white-space: nowrap;
}
.Carrier_Name_hover {
  width: auto;
  font-size: 12;
  font-weight: 700;
  position: absolute;
  margin: -80 0 0 20;
  background-color: #edf4fb;

  white-space: nowrap;
}
.Guaranteed_Icon_Clicked {
  /* height: 40;
  width: 40; */
  background-color: rgb(184, 204, 242);
}
.Guaranteed_Icon_Hovered {
  /* height: 30;
  width: 30; */
  background-color: #e3e3e3;
}

.Carrier_Name_Loading {
  width: auto;
  font-size: 12;
  font-weight: 700;
  position: absolute;
  margin: -90 0 0 20;
  background-color: white;
  white-space: nowrap;
}
.Quick_Quote_Carrier_div {
  /* margin-top: 10; */
  margin-bottom: 40;
  outline: 1px solid #e3e3e3;
  border-radius: 5px;
  position: relative;
  /* width: 50vw; */
}

.scroll {
  /* padding: 10 7 0 2; */
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}
/* .scroll::-webkit-scrollbar {
} */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(80, 157, 235);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(80, 157, 235);
}
::-webkit-scrollbar {
  height: 10px; /* Set the thickness for the horizontal scrollbar */
  width: 10px; /* Set the thickness for the vertical scrollbar */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px; /* Rounded edges */
}

::-webkit-scrollbar-thumb:hover {
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}
.Err_Msg_Div {
  display: flex;
  align-items: center;
  border-radius: 20;
  background-color: rgb(194, 25, 25);
  color: white;
  height: 30;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.vl {
  margin: 0 0 0 40;
  border-left: 1px solid;
  height: 0px;
}
.vl_Charges {
  margin: 0 10 0 10;
  border-left: 1px solid;
  height: 0px;
}
.Table_Head {
  background-color: #1976d2;
  color: white;
  min-width: 900;
}

.Table table {
  border: 1px solid rgb(204, 198, 198);
  border-collapse: collapse;
}
.Table th {
  border: 1px solid rgb(204, 198, 198);
  border-collapse: collapse;
  padding: 5px;
}
.Table td {
  border: 1px solid rgb(204, 198, 198);
  border-collapse: collapse;
  padding: 5px;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #e3e3e3 !important;
}
.css-1x7skt0 {
  background-color: #e3e3e3 !important;
}
.call-phone-number {
  color: black;
}
.call-phone-number:hover {
  color: #1976d2;
}
.Chat_Parent {
  border: 0.5px rgb(207, 207, 207) solid;
  height: 70vh;
  position: relative;
  border-radius: 10px;
}
.Chat_Child {
  border: 1px rgb(236, 230, 230) solid;
  overflow-y: scroll;
  height: 55vh;

  /* width: 32vw; */
}
.User_Input {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 50 !important;
  border: 0.5px rgb(207, 207, 207) solid !important;
  border-bottom: 0 !important;
}
.User_Input_Icon {
  position: absolute;
  bottom: 0;
  right: 0%;
  width: 10%;
  height: 100;
  cursor: pointer;
  /* border: 0.5px rgb(207, 207, 207) solid;
  border-bottom: 0; */
}
.User_Input:active {
  border: 0.5px rgb(207, 207, 207) solid;
  border-bottom: 0;
}
.User_Input:hover {
  /* cursor: pointer; */
  border: 1.5px rgb(207, 207, 207) solid;
  /* border-bottom: 0; */
}
.Chat_Carrier_Logo {
  background-color: red;
}
/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
} */

/* tr:nth-child(even) {
  background-color: #dddddd;
} */

.table-class {
  border-collapse: collapse;
  width: 100%;
}

.table-class th,
.table-class td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table-class th {
  background-color: #4caf50;
  color: white;
}
.table-class tr:nth-child(even) {
  /* background-color: #f2f2f2; */
}
.chat-message {
  display: flex;
  align-items: flex-end;
  margin: 8px;
  max-width: 70%;
  padding: 10px;
  border-radius: 15px;
  position: relative;
  font-size: 16px;
  word-break: break-word;
}

.message-text {
  flex: 1;
}

.my-message {
  background-color: #0078ff;
  color: white;
  align-self: flex-end;
}

.other-message {
  background-color: #f0f0f0;
  color: #333;
}

.arrow-left,
.arrow-right {
  width: 0;
  height: 0;
  position: absolute;
}

.arrow-left {
  left: -15px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid #f0f0f0; /* Match the background color of other messages */
}

.arrow-right {
  right: -15px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #0078ff; /* Match the background color of my messages */
}
