.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: none;
}
/* .css-z7uhs0-MuiStepConnector-line {
  margin: 20 25 0 25;
} */
/* .css-uhb5lp {
    min-width: 900;
  } */
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #2196f3;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #2196f3;
}
.PickupDialogParent {
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87);
}
.PickupCloseIcon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.56);
}
.PickupDailogContent {
  width: 900px;
  height: 730px;
  overflow-y: scroll;
  border-radius: 4px;
}
.PickupStepperParent {
  height: 62px;
  width: 852px;
  left: 24px;
  /* right: 24px; */
  top: 24px;
  border-radius: 0px;
  margin-top: 20;
}

.css-zpcwqm-MuiStepConnector-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
}
.PicupStepper {
  cursor: pointer;
  background-color: aliceblue;
}
.LocationsClass {
  display: flex;
  /* justify-content: space-evenly; */
}
.ParentFlexDiv {
  /* margin-left: 10vw; */
  display: flex;
  align-items: 'start';
  flex-direction: column;
}
.OriginTitle {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}
.DestinationTitle {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 15;
}

.ServicesTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 15;
  /* margin-right: 350; */
}
.ServicesTitleDelivery {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 15;
  margin-left: 180;
}
.AdditionalFeeTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  margin: 10 0 0 0;
}
.ServicesSwitches {
  margin-top: 15px;
  display: flex;
  width: 852px;
}
.PreviewServicesSwitches {
  /* margin-bo: 0px; */
  display: flex;
  /* width: 852px; */
}
.ServicesSwitcheLabels {
  font-size: 14px;
  font-weight: 500;
}
.TopPreviewSwitcheLabels {
  margin-top: 10;
  font-size: 14px;
  font-weight: 500;
}
.PreviewSwitcheLabels {
  margin-top: 10;
  font-size: 14px;
  font-weight: 500;
}
.LocationTextField {
  color: rgba(178, 46, 46, 0.38);

  margin-top: 0;
}
.NextBtnDiv {
  /* background-color: #2196f3; */
  margin: 60px 0px 0px 0px;
  display: flex;
  justify-content: flex-end;
  /* flex-direction: column; */
}
.NextBtnRates {
  margin-top: 10;
  display: flex;
  justify-content: flex-end;
}
.NextBtnRates_BookShipmet {
  margin: -50px 0px 0px 75%;
  padding: 10px;
  /* position: absolute; */
  display: flex;
  justify-content: flex-end;
}
.BtnColor {
  background-color: #2196f3;
  margin: 0 0 0 10;
}
.NextBtnIcon {
  font-size: 20;
  margin-left: 10;
}
.PrevBtnIcon {
  font-size: 20;
  margin-right: 10;
}
.MarginTop {
  margin-top: 25;
}
.Grid_Item_User {
  background-color: fff;
  padding: 30;
  margin: 10 20vw 5 22vw;
}
.Grid_Item_Create_Role {
  background-color: fff;
  /* padding: 20; */
  margin: 3 70 0 70;
  display: flex;
  justify-content: center;
}
.Grid_Item_User_mobile {
  background-color: fff;
  padding: 30;
  margin: 10 2vw 5 5vw;
}
.Grid_Item {
  background-color: fff;
  padding: 30;
  margin: 10 5vw 10 5vw;
}
.Grid_Item_Get_Quote {
  background-color: fff;
  padding: 50;
  margin: 10 5vw 10 5vw;
}
.RightMarginItems {
  margin-top: 25;
  margin-left: 15;
}
.scroll ::-webkit-scrollbar {
  display: none;
}
.Admin_Img_mobile {
  height: 90;
  width: 90;
  border-radius: 60px;
  outline: solid 3px rgb(187, 187, 187);
  margin: 20 0 0 20;
}
.Admin_Img {
  height: 120;
  width: 120;
  border-radius: 60px;
  outline: solid 3px rgb(187, 187, 187);
  margin: 40 0 0 20;
}
.MuiTypography-root.MuiTypography-h6 {
  color: #4e79d2;
}
.Hazmat {
  border-style: solid;
  border-width: thin;
  border-color: rgb(194, 196, 197);
  width: 180;
  height: 35;
  border-radius: 3px;
}
.Stackable {
  margin-top: 8;
  border-style: solid;
  border-width: thin;
  border-color: rgb(194, 196, 197);
  width: 280;
  height: 34;
  font-size: 12;
  border-radius: 3px;
}
.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  min-width: 50px;
  width: 95px;
}
.OuterCopyNmb {
  text-align: center;
  /* width: 350; */
  background-color: rgb(232, 235, 235);
  outline: solid rgb(247, 252, 252);
  margin: 0 140 0 140;
  outline-width: 1px;
  border-radius: 3px;
}
.CopyNmb {
  /* outline: white; */
  background-color: rgb(232, 235, 235);
  border: 0px;
  width: 90;
  text-align: center;
}
.ParentCopyNmb {
  display: flex;
  justify-content: center;
}
.InnerDialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 500;
    height: 400;
  }
}
.Other_Carrier_Dialog {
  /* max-width: 200; */
}
.Rates_Tab {
  margin-top: 20;
  display: flex;
  justify-content: space-around;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: rgba(30, 46, 170, 0.08);
  border-radius: 12px;
  /* max-height: 150; */
}
.carriers_logo {
  width: 36;
  height: 36;
  object-fit: cover;
}
.carriers_logo_Parent {
  background-color: rgb(239, 243, 247);
  border-radius: 50px;
  width: 36;
  height: 36;
  margin: 0 10 0 -15;
}
.others_carrier_logo {
  margin: 5 0 0 5;
  color: #4e79d2;
}
.vl {
  border-left: 1px solid rgb(127, 127, 127);
  height: 55px;
  margin: -10 0 0 20px;
}
.User_Upload_Image {
  position: absolute;
  background-color: rgb(244, 244, 244);

  border-bottom-right-radius: 451px;
  border-bottom-left-radius: 451px;
  margin: -36px 0px 0px 26px;
  width: 108px;
  height: 38px;
  align-self: center;
  align-content: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

/* CSS */
.table-row-buttons-users {
  position: absolute;
  display: none;
  margin-top: -40px;
  /* width: 230; */
  background: #eeeeee00;
}
.table-row-buttons-users-mobile {
  /* position: absolute;
  display: none; */
  margin-top: -40px;
  /* width: 230; */
  background: #eeeeee00;
}
.MuiTableRow-root:hover .table-row-buttons-users {
  display: block;
}
